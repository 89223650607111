/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
const Section = lazy(() => import("./Section"));

const Intro = () => {
  // Define refs for each section
  const sectionOneRef = useRef<HTMLDivElement | null>(null);
  const sectionTwoRef = useRef<HTMLDivElement | null>(null);
  const sectionThreeRef = useRef<HTMLDivElement | null>(null);
  const sectionFourRef = useRef<HTMLDivElement | null>(null);
  const sectionFiveRef = useRef<HTMLDivElement | null>(null);

  const [currentSection, setCurrentSection] = useState<number>(0);

  const [sectionOneAnimation, setSectionOneAnimation] = useState(null);
  const [sectionTwoAnimation, setSectionTwoAnimation] = useState(null);
  const [sectionThreeAnimation, setSectionThreeAnimation] = useState(null);
  const [sectionFourAnimation, setSectionFourAnimation] = useState(null);
  const [sectionFiveAnimation, setSectionFiveAnimation] = useState(null);

  const sections = [
    {
      title:
        "AI-Assisted SOAP Notes for Physical Therapists | Streamline Clinical Documentation",
      description:
        "Revolutionize your physical therapy practice with AI-assisted SOAP note generation. Modernize, automate, and enhance clinical documentation, saving time, reducing physical therapist burnout, and improving accuracy. Discover our AI-powered solutions today.",
      keywords:
        "AI SOAP notes, physical therapy documentation, AI clinical notes, automated SOAP notes, AI in physical therapy, AI services for EMR and HER systems.",
      altText:
        "AI-powered interface for generating near autonomous SOAP clinical notes in physical therapy.",
      url: "fownd.care/ai-soap-notes-physical-therapy",
    },
    {
      title:
        "AI-assisted capture of language, speakers, and clinical context in real-time",
      description:
        "FOWND’s custom multi-modal model implementation captures and transcribes patient and treatment context to create a highly accurate patient-rich subjective assessment.",
      keywords:
        "Transcription, Clinical Context in natural language processing",
      altText:
        "AI and Natural language processing powered interface capturing subjective assessments in physical therapy.",
      url: "fownd.care/ai-assisted-subjective-assessment-physical-therapy",
    },
    {
      title:
        "Computer vision using simple web camera to capture Objective details of the patient",
      description:
        "FOWND’s novel approach to capturing P.I.I free clinical data to accurately assist in assessing ROM, posture, and functional movements. Standardizing measurements and helping further train AI models for consistent real-world clinical results.",
      keywords:
        "Vision-based Objective Assessments, Pose Estimation, Posture assessment, functional movement analysis, Edge Inferencing.",
      altText:
        "Computer Vision based inference of patients helping deliver accurate and consistent care in physical therapy.",
      url: "fownd.care/fownd-computer-vision-objective-assessment-physical-therapy",
    },
    {
      title:
        "FOWND predictive and analytical AI models help with note compliance and billing readiness",
      description:
        "FOWNDs used unique data classification and contextual data mining to assist physical therapists in capturing the right therapy and medical codes for diagnosis and also provides them invaluable data to build the best care plans and home exercise programs for the patient. No more second guessing, endless clicking to build plans and selecting the right billable codes.",
      keywords:
        "Data Analysis, SOAP note Accuracy, Compliance, reduced burnout, reduced administrative tasks",
      altText:
        "Autonomous human-in-the-loop AI, note that helps reduce physical therapy workloads, creating the a note before the session ends for sign-off.",
      url: "fownd.care/fownd-data-driven-predictive-physical-therapy-soap-note",
    },
    {
      title:
        "FOWND develops a real-time autonomous note-keeping clinical skill and patient care at the forefront from intake to discharge.",
      description:
        "FOWND approach doesn’t just end with transcription. FOWND produces a comprehensive SOAP note backed by data, reducing the need for lookups and second-guessing a care plan while also helping improve intake and tracking through the patient care lifecycle. Clinical notes get better with each session with learnings and data.",
      keywords:
        "Comprehensive, Compliant, reduced burnout, reduced administrative tasks, remote therapy monitoring, improved physical rehabilitation.",
      altText:
        "AI that supports every patient visit, assists in monitoring progress, and enables remote monitoring applications for physical therapy with minimal guesswork.",
      url: "fownd.care/fownd-ai-comprehensive-complaint-soap-note",
    },
  ];

  // Function to update metadata
  const updateMetadata = (index: number) => {
    const slide = sections[index];
    document.title = slide.title;
    const metaDescription = document.querySelector(
      'meta[name="description"]'
    ) as HTMLMetaElement;
    const metaKeywords = document.querySelector(
      'meta[name="keywords"]'
    ) as HTMLMetaElement;
    const metaImage = document.querySelector(
      'meta[name="image"]'
    ) as HTMLMetaElement;
    const linkCanonical = document.querySelector(
      'link[rel="canonical"]'
    ) as HTMLLinkElement;

    if (metaDescription) metaDescription.content = slide.description;
    if (metaKeywords) metaKeywords.content = slide.keywords;
    if (metaImage) metaImage.content = slide.altText;
    if (linkCanonical) linkCanonical.href = slide.url;
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const sectionOne = sectionOneRef.current;
    const sectionTwo = sectionTwoRef.current;
    const sectionThree = sectionThreeRef.current;
    const sectionFour = sectionFourRef.current;
    const sectionFive = sectionFiveRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === sectionOne) {
            setCurrentSection(0);
            if (!sectionOneAnimation) fetchAnimation(0);
          }
          if (entry.target === sectionTwo) {
            setCurrentSection(1);
            if (!sectionTwoAnimation) fetchAnimation(1);
          }
          if (entry.target === sectionThree) {
            setCurrentSection(2);
            if (!sectionThreeAnimation) fetchAnimation(2);
          }
          if (entry.target === sectionFour) {
            setCurrentSection(3);
            if (!sectionFourAnimation) fetchAnimation(3);
          }
          if (entry.target === sectionFive) {
            setCurrentSection(4);
            if (!sectionFiveAnimation) fetchAnimation(4);
          }
        }
      });
    }, options);

    if (sectionOne) observer.observe(sectionOne);
    if (sectionTwo) observer.observe(sectionTwo);
    if (sectionThree) observer.observe(sectionThree);
    if (sectionFour) observer.observe(sectionFour);
    if (sectionFive) observer.observe(sectionFive);

    return () => {
      if (sectionOne) observer.unobserve(sectionOne);
      if (sectionTwo) observer.unobserve(sectionTwo);
      if (sectionThree) observer.unobserve(sectionThree);
      if (sectionFour) observer.unobserve(sectionFour);
      if (sectionFive) observer.unobserve(sectionFive);
    };
  }, [
    sectionOneAnimation,
    sectionTwoAnimation,
    sectionThreeAnimation,
    sectionFourAnimation,
    sectionFiveAnimation,
  ]);

  // Function to fetch animation JSON data based on the section number
  const fetchAnimation = async (sectionIndex: number) => {
    let url = "";
    switch (sectionIndex) {
      case 0:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide1_Final.json";
        break;
      case 1:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide2_Final.json";
        break;
      case 2:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide3_Final_2.json";
        break;
      case 3:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide4_Final.json";
        break;
      case 4:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide5_Final.json";
        break;
      default:
        return;
    }

    try {
      const response = await fetch(url);
      const jsonData = await response.json();

      switch (sectionIndex) {
        case 0:
          setSectionOneAnimation(jsonData);
          break;
        case 1:
          setSectionTwoAnimation(jsonData);
          break;
        case 2:
          setSectionThreeAnimation(jsonData);
          break;
        case 3:
          setSectionFourAnimation(jsonData);
          break;
        case 4:
          setSectionFiveAnimation(jsonData);
          break;
      }
    } catch (error) {
      console.error("Error loading animation:", error);
    }
  };

  useEffect(() => {
    updateMetadata(currentSection);
  }, [currentSection]);

  return (
    <>
      {" "}
      {/* Section One */}
      <div ref={sectionOneRef} id="section-1" className="section-1">
        <div className="flex flex-col md:flex-row justify-center bg-gray md:min-h-screen lg:px-28 lg:py-3">
          <div className="flex flex-col px-4 pt-8 md:pt-0 md:my-4 md:justify-center items-center md:items-start md:flex-1">
            <div className="font-productSans font-bold text-cyan-blue text-[36px] sm:text-[36px] md:text-[36px] lg:text-[48px] xl:text-[58px] 2xl:text-[60px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] max-w-[500px] text-center md:text-left md:tracking-wide">
              Fownding Modern
              <span className="block font-productSans font-bold text-white text-[36px] sm:text-[36px] md:text-[36px] lg:text-[48px] xl:text-[58px] 2xl:text-[60px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] md:tracking-wide">
                Physical Therapy Documentation
              </span>
            </div>
            <div className="font-productSans font-normal text-cyan-blue text-[18px] sm:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[26px] 2xl:text-[26px] max-w-[500px] text-center md:text-left mt-2 md:mt-4">
              Intuitive, AI-assisted clinical SOAP notes. Let technology take
              care of your documentation, and you take care of your patients!
            </div>
          </div>
          <div id="animation-1" className="px-4 md:my-auto w-full flex items-center justify-center flex-1 h-auto max-h-[360px]">
            <Suspense
              fallback={
                <img src="./assets/images/fallback/Slide1_fallback.png" />
              }
            >
              {sectionOneAnimation ? (
                <Section animationData={sectionOneAnimation} />
              ) : (
                <img src="./assets/images/fallback/Slide1_fallback.png" />
              )}
            </Suspense>
          </div>
        </div>
      </div>
      {/* Section Two */}
      <div
        id="section-2"
        ref={sectionTwoRef}
        className="bg-lightGray md:h-screen lg:px-28 lg:py-3 flex flex-col md:flex-row"
      >
        <div id="animation-2" className="px-4 md:flex-1 my-auto flex items-center justify-center h-auto max-h-[450px]">
          <Suspense
            fallback={
              <img src="./assets/images/fallback/Slide2_fallback.png" />
            }
          >
            {sectionTwoAnimation ? (
              <Section animationData={sectionTwoAnimation} />
            ) : (
              <img src="./assets/images/fallback/Slide2_fallback.png" />
            )}
          </Suspense>
        </div>
        <div className="flex-1 text-center px-4 py-12 md:py-0 md:text-left flex items-center md:items-center justify-center">
          <p className="text-white font-productSans font-normal mx-4 lg:pl-9 text-[28px] sm:text-[28px] md:text-[36px] lg:text-[48px] lg:leading-[60px] xl:text-[52px] xl:leading-[64px] 2xl:text-[52px] 2xl:leading-[64px]">
            Harness <span className="text-[#434d57]">AI</span> to capture{" "}
            <span className="text-[#434d57]">clinical details</span> in
            conversation to build note automatically
          </p>
        </div>
      </div>
      {/* Section Three */}
      <div
        id="section-3"
        ref={sectionThreeRef}
        className="md:h-screen bg-gray lg:px-28 py-3 flex flex-col-reverse md:flex-row"
      >
        <div className="px-4 py-8 md:py-0 md:flex-1 text-center md:text-left md:my-0 flex items-center justify-start">
          <p className="text-cyan-blue font-productSans font-normal text-[28px] sm:text-[28px] md:text-[36px] lg:text-[42px] lg:leading-[60px] xl:text-[46px] xl:leading-[64px] 2xl:text-[46px] 2xl:leading-[64px]">
            <span className="text-[#ffffff]">Computer Vision</span> and AI that
            recognize and accurately capture a range of motion, posture, gait,
            and functional <span className="text-[#ffffff]">movements</span>
          </p>
        </div>
        <div className="px-2 py-4 md:flex-1 my-auto flex items-center justify-end h-auto max-h-[450px]">
          <Suspense
            fallback={
              <img src="./assets/images/fallback/Slide3_fallback.png" />
            }
          >
            {sectionThreeAnimation ? (
              <Section animationData={sectionThreeAnimation} />
            ) : (
              <img src="./assets/images/fallback/Slide3_fallback.png" />
            )}
          </Suspense>
        </div>
      </div>
      {/* Section Four */}
      <div
        id="section-4"
        ref={sectionFourRef}
        className="md:h-screen bg-lightGray lg:px-28 py-3 flex flex-col md:flex-row items-center"
      >
        <div className="flex-1 my-auto flex items-center justify-center h-auto min-h-[380px]">
          <Suspense
            fallback={
              <img src="./assets/images/fallback/Slide4_fallback.png" />
            }
          >
            {sectionFourAnimation ? (
              <Section animationData={sectionFourAnimation} />
            ) : (
              <img src="./assets/images/fallback/Slide4_fallback.png" />
            )}
          </Suspense>
        </div>
        <div className="md:flex-1 px-4 py-8 md:py-0 text-center md:text-left md:my-0 flex items-center justify-center">
          <p className="text-white font-productSans font-normal mx-4 lg:pl-9 text-[28px] sm:text-[28px] md:text-[36px] lg:text-[42px] lg:leading-[60px] xl:text-[46px] xl:leading-[64px] 2xl:text-[46px] 2xl:leading-[64px]">
            Informed AI models that can suggest relevant billable diagnostic
            codes and goals that support medical necessity
          </p>
        </div>
      </div>
      {/* Section Five */}
      <div
        id="section-5"
        ref={sectionFiveRef}
        className="md:h-screen bg-gray py-3 lg:px-28 flex flex-col-reverse md:flex-row items-center"
      >
        <div className="flex-1 px-4 py-8 md:py-0 text-center md:text-left flex items-center justify-start">
          <p className="text-cyan-blue font-productSans font-normal text-[28px] sm:text-[28px] md:text-[36px] lg:text-[42px] lg:leading-[60px] xl:text-[42px] xl:leading-[64px] 2xl:text-[42px] 2xl:leading-[64px]">
            <span className="text-[#ffffff]">AI-generated</span> plan of care,
            goals, and home exercise programs are seamlessly delivered, allowing
            for{" "}
            <span className="text-[#ffffff]">
              real-time editing and adaptation
            </span>{" "}
            as the patient progresses towards discharge
          </p>
        </div>
        <div className="flex-1 my-auto w-full flex items-center justify-center h-auto max-h-[400px]">
          <Suspense
            fallback={
              <img src="./assets/images/fallback/Slide5_fallback.png" />
            }
          >
            {sectionFiveAnimation ? (
              <Section animationData={sectionFiveAnimation} />
            ) : (
              <img src="./assets/images/fallback/Slide5_fallback.png" />
            )}
          </Suspense>
        </div>
      </div>
    </>
  );
};

const DarkCallback = () => (
  <>
    <div className="w-full h-full flex flex-col items-center justify-center mx-auto">
      <svg
        width="69"
        height="86"
        viewBox="0 0 69 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.5469 59.8127V81.6586C31.5469 83.7172 33.2157 85.386 35.2744 85.386C37.333 85.386 39.0019 83.7172 39.0019 81.6586V70.6428C39.0019 66.5811 42.2945 63.2885 46.3561 63.2885H49.9965C52.0476 63.2885 53.7104 61.6258 53.7104 59.5747C53.7104 57.5236 52.0476 55.8608 49.9965 55.8608H42.7157C40.6646 55.8608 39.0019 54.198 39.0019 52.1469C39.0019 50.0958 40.6646 48.4331 42.7157 48.4331H64.8907C66.8393 48.4331 68.4189 46.8534 68.4189 44.9049C68.4189 42.9563 66.8393 41.3767 64.8907 41.3767H49.9829C39.801 41.3767 31.5469 49.6308 31.5469 59.8127Z"
          fill={"#606e7d"}
        />
        <path
          d="M36.8721 25.5733L36.8721 3.72744C36.8721 1.6688 35.2032 -6.11691e-05 33.1446 -6.13343e-05C31.0859 -6.14996e-05 29.4171 1.66879 29.4171 3.72744L29.4171 14.7432C29.4171 18.8048 26.1245 22.0975 22.0628 22.0975L18.4224 22.0975C16.3713 22.0975 14.7086 23.7602 14.7086 25.8113C14.7086 27.8624 16.3713 29.5252 18.4224 29.5252L25.7032 29.5252C27.7543 29.5252 29.4171 31.1879 29.4171 33.2391C29.4171 35.2902 27.7543 36.9529 25.7032 36.9529L3.52824 36.9529C1.57968 36.9529 6.15566e-05 38.5325 6.13711e-05 40.4811C6.11856e-05 42.4297 1.57967 44.0093 3.52823 44.0093L18.4361 44.0093C28.618 44.0093 36.8721 35.7552 36.8721 25.5733Z"
          fill={"#606e7d"}
        />
      </svg>
    </div>
  </>
);
const LightCallback = () => (
  <>
    <div className="w-full h-full flex flex-col items-center justify-center mx-auto">
      <svg
        width="69"
        height="86"
        viewBox="0 0 69 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.5469 59.8127V81.6586C31.5469 83.7172 33.2157 85.386 35.2744 85.386C37.333 85.386 39.0019 83.7172 39.0019 81.6586V70.6428C39.0019 66.5811 42.2945 63.2885 46.3561 63.2885H49.9965C52.0476 63.2885 53.7104 61.6258 53.7104 59.5747C53.7104 57.5236 52.0476 55.8608 49.9965 55.8608H42.7157C40.6646 55.8608 39.0019 54.198 39.0019 52.1469C39.0019 50.0958 40.6646 48.4331 42.7157 48.4331H64.8907C66.8393 48.4331 68.4189 46.8534 68.4189 44.9049C68.4189 42.9563 66.8393 41.3767 64.8907 41.3767H49.9829C39.801 41.3767 31.5469 49.6308 31.5469 59.8127Z"
          fill={"#ffffff"}
        />
        <path
          d="M36.8721 25.5733L36.8721 3.72744C36.8721 1.6688 35.2032 -6.11691e-05 33.1446 -6.13343e-05C31.0859 -6.14996e-05 29.4171 1.66879 29.4171 3.72744L29.4171 14.7432C29.4171 18.8048 26.1245 22.0975 22.0628 22.0975L18.4224 22.0975C16.3713 22.0975 14.7086 23.7602 14.7086 25.8113C14.7086 27.8624 16.3713 29.5252 18.4224 29.5252L25.7032 29.5252C27.7543 29.5252 29.4171 31.1879 29.4171 33.2391C29.4171 35.2902 27.7543 36.9529 25.7032 36.9529L3.52824 36.9529C1.57968 36.9529 6.15566e-05 38.5325 6.13711e-05 40.4811C6.11856e-05 42.4297 1.57967 44.0093 3.52823 44.0093L18.4361 44.0093C28.618 44.0093 36.8721 35.7552 36.8721 25.5733Z"
          fill={"#ffffff"}
        />
      </svg>
    </div>
  </>
);
export default Intro;
