import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import React, { useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ItemsContext } from "./data/context";
import clsx from "clsx";
import ScrollToTop from "./Hooks/useNavigateToTop";
import Hotjar from "@hotjar/browser";

function App() {
  const { isPolicyModel, setIsPolicyModelOpen } = useContext(ItemsContext);
  const [showError, setShowError] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const handleScreenClick = () => {
    setShowAnimation(true);
    setTimeout(() => setShowAnimation(false), 1000); // Set animation duration (1 second in this case)
  };

  useEffect(() => {
    const policy = localStorage.getItem("fownd-policy");

    if (policy === "false") {
      const siteId = 3714868;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
      console.log("Recording Analytics...");
    }

    if (policy === "true") {
      setIsPolicyModelOpen(true);
    }

    if (isPolicyModel) {
      document.addEventListener("click", handleScreenClick);
    }

    return () => {
      document.removeEventListener("click", handleScreenClick);
    };
  }, [setIsPolicyModelOpen, isPolicyModel]);

  return (
    <>
      {isPolicyModel && (
        <Transition appear show={isPolicyModel} as={React.Fragment}>
          <Dialog
            as="div"
            className="relative"
            onClose={() => setShowError(true)}
            static
          >
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed bottom-0 left-0 right-5 overflow-y-auto">
              <div className="flex min-h-full items-center justify-end p-4 text-center">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-4 md:p-6 text-left align-middle shadow-xl transition-all">
                    <div className="lg:mt-2 lg:h-full hidden lg:block">
                      {showError && (
                        <div className="text-[#FF0000] font-dmSans font-semibold">
                          {" "}
                          Please Accept Policy!!
                        </div>
                      )}
                      <div className="lg:font-archivo lg:text-lg lg:font-normal lg:mb-3">
                        We use <span className="lg:text-gray font-bold">cookies</span> to
                        improve your experience
                      </div>
                      <div className="lg:grid lg:gap-3">
                        <div className="text-[15px] lg:font-archivo lg:font-normal lg:text-gray">
                          <p>
                            This site uses tracking technologies using session cookies. You may opt in
                            or opt out of the use of these technolgies.
                          </p>
                        </div>
                        <div>
                          <div className="lg:flex lg:flex-row justify-center lg:justify-end lg:gap-2 lg:items-center">
                            <button
                              type="button"
                              className={clsx(
                                showAnimation && "under-pressure",
                                "lg:bg-white lg:font-archivo lg:inline-flex lg:h-10 lg:select-none lg:items-center lg:justify-center lg:w-30 lg:text-gray lg:text-[16px] lg:px-5 lg:leading-5 lg:border-gray lg:border-[1px] lg:rounded-[5px] lg:cursor-pointer"
                              )}
                              onClick={() => {
                                localStorage.removeItem("fownd-policy");
                                localStorage.setItem("fownd-policy", "true");
                                setIsPolicyModelOpen(false);
                              }}
                            >
                              Reject All
                            </button>
                            <button
                              type="button"
                              className={clsx(
                                showAnimation && "under-pressure",
                                " lg:bg-gray lg:font-archivo lg:inline-flex lg:h-10 lg:select-none lg:items-center lg:justify-center lg:w-30 lg:text-[16px] lg:text-white lg:px-5 lg:leading-5 lg:outline-none lg:rounded-[5px] lg:cursor-pointer"
                              )}
                              onClick={() => {
                                localStorage.removeItem("fownd-policy");
                                localStorage.setItem("fownd-policy", "false");
                                setIsPolicyModelOpen(false);
                              }}
                            >
                              Accept All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 h-full block lg:hidden">
                      {showError && (
                        <div className="text-[#FF0000] font-dmSans font-semibold">
                          {" "}
                          Please Accept Policy!!
                        </div>
                      )}
                      <div className="flex items-center space-x-2">
                        <div className="font-archivo lg:text-xl font-normal mb-3">
                          We use <span className="text-gray">cookies</span> to
                          improve your experience
                        </div>
                      </div>
                      <div className="lg:grid lg:grid-flow-col lg:gap-3">
                        <div className="font-archivo font-normal text-[15px] mb-4 lg:text-base text-gray">
                          <p>
                          This site uses tracking technologies using session cookies. You may opt in
                          or opt out of the use of these technolgies.
                          </p>
                        </div>
                        <div>
                          <div className="flex space-x-2 justify-end items-center mt-2">
                            <button
                              type="button"
                              className={clsx(
                                showAnimation && "under-pressure",
                                " bg-gray inline-flex h-8 select-none items-center justify-center w-30 text-white text-[14px] px-7 font-small leading-5 outline-none rounded-[5px] cursor-pointer"
                              )}
                              onClick={() => {
                                localStorage.removeItem("fownd-policy");
                                localStorage.setItem("fownd-policy", "true");
                                setIsPolicyModelOpen(false);
                              }}
                            >
                              Reject All
                            </button>
                            <button
                              type="button"
                              className={clsx(
                                showAnimation && "under-pressure",
                                " bg-gray inline-flex h-8 select-none items-center justify-center w-30 text-white text-[14px] px-7 font-small leading-5 outline-none rounded-[5px] cursor-pointer"
                              )}
                              onClick={() => {
                                localStorage.removeItem("fownd-policy");
                                localStorage.setItem("fownd-policy", "false");
                                setIsPolicyModelOpen(false);
                              }}
                            >
                              Accept All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      <Router>
        <ScrollToTop />
        <AppRoutes />
        <Toaster position="top-right" />
      </Router>
    </>
  );
}

export default App;
