import { useNavigate } from "react-router-dom";
import { Button } from "../component/ui";

const Contact = () => {
  let navigate = useNavigate();

  return (
    <section>
      {/* Join the team Section */}
      <div className="flex flex-col bg-gray justify-center px-8 lg:px-0 py-12 xl:py-24 2xl:py-20">
        <div className="my-4 font-productSans font-bold text-white text-[36px] sm:text-[36px] md:text-[36px] lg:text-[48px] xl:text-[52px] 2xl:text-[56px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-center md:tracking-wide">
          Join our growing team!
        </div>

        <div className="md:mx-[130px] my-4 font-dmsans font-normal text-white text-[18px] sm:text-[18px] md:text-[18px] lg:text-[20px] xl:text-[20px] 2xl:text-[22px] text-center">
          We are always looking for talented, passionate transformation agents
          in the world. If you are a healthcare professional, computer
          scientist, developer, data scientist, AI specialist, looking for the
          biggest challenge in health tech, reach out to us!
        </div>

        <button
          onClick={() => navigate(`/contact-us`)}
          className="my-6 text-gray font-dmsans font-medium text-18px lg:text-[18px] mx-auto py-2 px-10 rounded-3xl bg-white hover:bg-gray hover:border-white hover:border-[1px] hover:text-white"
        >
          Join Us
        </button>
      </div>

      {/* Help us scale  */}
      <div className="my-12 mx-8 lg:my-16 lg:mx-24">
        <div className="flex flex-col lg:flex-row border-gray border-[1px] justify-between p-12 lg:p-20 rounded-3xl shadow-xl hover:scale-105 transform transition-transform duration-500 ease-in-out hover:shadow-2xl">
          <div className="lg:w-[60%]">
            <div className="font-productSans font-bold text-center lg:text-left text-gray text-[36px] sm:text-[36px] md:text-[36px] lg:text-[42] xl:text-[42px] 2xl:text-[48px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] md:tracking-wide">
              Help us Scale!
            </div>
            <div className="my-4 pb-4 font-dmsans font-normal text-center lg:text-left text-gray text-[18px] sm:text-[18px] md:text-[18px] lg:text-[18px] xl:text-[18px] 2xl:text-[22px]">
              Are you a visionary looking to become a catalyst to an industry
              yearning for a technology revolution ? Join us by investing in our
              growth and help us innovate and disrupt rapidly.
            </div>
          </div>

          <div className="flex justify-center items-center">
            <button
              onClick={() => navigate(`/help-scale`)}
              className="h-min-content text-white font-dmsans font-medium text-18px lg:text-[18px] py-2 px-10 rounded-3xl bg-gray hover:bg-white hover:border-gray hover:border-[1px] hover:text-gray"
            >
              Connect with Fownd
            </button>
          </div>
        </div>
      </div>

      {/* Social Media */}
      <div className="flex flex-col lg:flex-row min-h-[100vh] mx-8 lg:mx-24">
        <div className="lg:w-[500px] lg:sticky lg:top-20 lg:self-start lg:py-20">
          <div className="font-productSans font-bold text-gray text-[36px] sm:text-[36px] md:text-[36px] lg:text-[42] xl:text-[42px] 2xl:text-[48px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-left md:tracking-wide">
            Help us improve the platform!
          </div>
          <div className="my-4 font-dmsans font-normal text-gray text-[18px] sm:text-[18px] md:text-[18px] lg:text-[18px] xl:text-[18px] 2xl:text-[22px] text-left">
            Are you a visionary looking to become a catalyst to an industry
            yearning for a technology revolution ? Join us by investing in our
            growth and help us innovate and disrupt rapidly.
          </div>
        </div>

        <div className="flex-1 p-2 lg:p-24">
          {/* Discord */}
          <div className="bg-customGray rounded-3xl p-12 mb-8 lg:mb-16 border-white border-[1px] text-center lg:sticky lg:top-40 lg:-rotate-1">
            <div className="flex flex-row gap-4 justify-center items-center">
              <img
                src="/assets/images/icons/discord.png"
                alt="discord"
                className="h-12 w-12"
              />
              <div className="font-productSans font-bold text-black text-[36px] sm:text-[36px] md:text-[36px] lg:text-[42] xl:text-[28px] 2xl:text-[28px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-left md:tracking-wide">
                Discord
              </div>
            </div>
            <div className="my-4 font-dmsans font-normal text-black text-[18px] sm:text-[18px] md:text-[18px] lg:text-[18px] xl:text-[18px] 2xl:text-[22px] text-center">
              Securely communicate, Influence features and test the platform
            </div>
            <button
              onClick={() => window.open("https://discord.gg/VcjDkXAwAt")}
              className="my-4 text-gray font-dmsans font-medium text-18px lg:text-[18px] mx-auto py-2 px-14 rounded-3xl bg-white hover:bg-lightGray hover:border-white hover:border-[1px] hover:text-white"
            >
              Join
            </button>
          </div>

          {/* Facebook */}
          <div className="bg-customGray rounded-3xl p-12 mb-8 lg:mb-16 border-white border-[1px] text-center lg:sticky lg:top-40 lg:rotate-1">
            <div className="flex flex-row gap-4 justify-center items-center">
              <img
                src="/assets/images/icons/facebook.png"
                alt="discord"
                className="h-12 w-12"
              />
              <div className="font-productSans font-bold text-black text-[36px] sm:text-[36px] md:text-[36px] lg:text-[42] xl:text-[28px] 2xl:text-[28px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-left md:tracking-wide">
                Facebook
              </div>
            </div>
            <div className="my-4 font-dmsans font-normal text-black text-[18px] sm:text-[18px] md:text-[18px] lg:text-[18px] xl:text-[18px] 2xl:text-[22px] text-center">
              Support us, keep in touch and be in the know
            </div>
            <button
              onClick={() =>
                window.open("http://www.facebook.com/groups/fownd/")
              }
              className="my-6 text-gray font-dmsans font-medium text-18px lg:text-[18px] mx-auto py-2 px-14 rounded-3xl bg-white hover:bg-lightGray hover:border-white hover:border-[1px] hover:text-white"
            >
              Join
            </button>
          </div>

          {/* Linkedin */}
          <div className="bg-customGray rounded-3xl p-12 text-center border-white border-[1px]  lg:sticky lg:top-40 lg:-rotate-1">
            <div className="flex flex-row gap-4 justify-center items-center">
              <img
                src="/assets/images/icons/linkedin.png"
                alt="discord"
                className="h-12 w-14"
              />
              <div className="font-productSans font-bold text-black text-[36px] sm:text-[36px] md:text-[36px] lg:text-[42] xl:text-[28px] 2xl:text-[28px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-left md:tracking-wide">
                LinkedIn
              </div>
            </div>
            <div className="my-4 font-dmsans font-normal text-black text-[18px] sm:text-[18px] md:text-[18px] lg:text-[18px] xl:text-[18px] 2xl:text-[22px] text-center">
              Support us, keep in touch and be in the know{" "}
            </div>
            <button
              onClick={() =>
                window.open("https://www.linkedin.com/company/fownd-inc")
              }
              className="my-6 text-gray font-dmsans font-medium text-18px lg:text-[18px] mx-auto py-2 px-14 rounded-3xl bg-white hover:bg-lightGray hover:border-white hover:border-[1px] hover:text-white"
            >
              Join
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
