import { useContext } from "react";

// import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Sidebar } from "../../ui/Modal";
import { ItemsContext } from "../../../data/context";
import { HamburgIcon, HamburgIconLight, SiteLogo } from "../../Icons";

const Navbar = ({ ...props }) => {
  let navigate = useNavigate();

  const { isSideBarOpen, setIsSideBarOpen } = useContext(ItemsContext);

  const openModal = () => {
    setIsSideBarOpen(true);
  };

  const closeModal = () => {
    setIsSideBarOpen(false);
  };

  return (
    <div className="sticky top-0 bg-gray z-50">
      <div className="bg-gray px-4 md:px-14 w-full py-1 z-10 flex justify-between lg:bg-transparent lg:backdrop-blur-none">
        <div>
          <SiteLogo
            onClick={() => navigate(`/`)}
            className="text-white cursor-pointer w-8 sm:w-12 h-full"
          />
        </div>

        {/* Desktop Navigation */}
          <div className="hidden md:flex justify-center items-center space-x-12">
            <HashLink
              to="/#section-1"
              className="text-white md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"  
            >
              Home
            </HashLink>
            <Link
              to="/about"
              className="text-white md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"
            >
              About
            </Link>
            <Link
              to="/founder-notes"
              className="text-white md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"
            >
              Founder's Note
            </Link>
           
            <Link
              to="/invest"
              className="text-white md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"
            >
              Contact
            </Link>
          </div>
        <div className="md:hidden flex justify-center items-center">
          {props.darkMenu ? (
            <HamburgIconLight
              className="cursor-pointer w-6 sm:w-[38px] h-6 sm:h-[38px]"
              onClick={openModal}
            />
          ) : (
            <HamburgIcon
              className="cursor-pointer w-6 sm:w-[38px] h-6 sm:h-[38px]"
              onClick={openModal}
            />
          )}
        </div>
      </div>

      <Sidebar isOpen={isSideBarOpen} closeModal={closeModal} />
    </div>
  );
};

export default Navbar;
