export const teamMembers = [
  {
    name: "Dr. Christina Rama",
    imgSrc: "/assets/images/teams/christina-2.png",
    description:
      "Christina is the founding Subject Matter Expert at FOWND with a doctorate in Physical Therapy, multiple certifications, and nearly two decades of experience",
    link: "/team/christina",
  },
  {
    name: "Vignesh Rama",
    imgSrc: "/assets/images/teams/vignesh-2.png",
    description:
      "I am a builder, and technology enthusiast at my core, I bring the technologist and architect skillset as a founding member of the team",
    link: "/team/vignesh",
  },
  {
    name: "Sowmya Shetty",
    imgSrc: "/assets/images/teams/sowmya-2.png",
    description:
      "A lifelong goal to better people’s lives is what connects her to FOWND and its core value. Sowmya is a dentist by education, a homemaker and a mother by choice, and an entrepreneur in making.",
    link: "/team/sowmya",
  },
  {
    name: "Rachel Stewart",
    imgSrc: "/assets/images/teams/rachel-2.png",
    description:
      "As a UX Designer, my hope is to improve how clinicians interact with their documentation system, thus allowing them to provide better patient care.",
    link: "/team/rachel",
  },
  {
    name: "Swagatha Mandal",
    imgSrc: "/assets/images/teams/swagatha-2.png",
    description:
      "A techie by heart and inventor by soul. Swagatha connects the dots between the live and life.  With 13 years of vast knowledge and experience in the field of Cloud Technologies ",
    link: "/team/swagata",
  },
];
