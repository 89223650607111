import { FormEvent, useEffect, useState } from "react";
import { Button, InputText } from "../component/ui";
import toast from "react-hot-toast";
import TypingText from "./TypingText";
import { FaArrowRight } from "react-icons/fa";

const Demo = () => {
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [formStep, setFormStep] = useState(1);
  const [formVisible, setFormVisible] = useState(false);
  const [exists, setExists] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const email_exists = sessionStorage.getItem("authToken");
    if (email_exists) {
      setExists(true);
    }
    setTimeout(() => setFormVisible(true), 1200);
  }, []);

  const submitFunction = async (e: FormEvent) => {
    e.preventDefault();
    if (formStep === 1) {
      // Sending the Email for OTP
      const emailData = {
        email: email,
        DBCheck: true,
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_DEMO_BACKEND_URL}/generateotp`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify(emailData),
          }
        );
        const data = await response.json();
        sessionStorage.setItem("secret_key", data.secret);

        setFormStep(2);
        toast.success("OTP sent to your Email ID.");
      } catch (err) {
        toast.error("Something wrong on our side! Please try again later.");
        console.log("Error while generateotp", err);
      }
    } else {
      // Verify OTP
      setLoading(true)
      const otpData = {
        secretkey: sessionStorage.getItem("secret_key"),
        code: OTP,
        email: email,
      };
      const response = await fetch(
        `${process.env.REACT_APP_DEMO_BACKEND_URL}/verigyotp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(otpData),
        }
      );

      const data = await response.json();

      setLoading(false);
      if (data.acces_token === "otp not worked") {
        toast.error("Wrong OTP. Please try again!");
      } else {
        sessionStorage.setItem("authToken", data.access_token);
        setExists(true);
      }
    }
  };

  const NotReceivedOTP = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const emailData = {
      email: email,
      DBCheck: true,
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DEMO_BACKEND_URL}/generateotp`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(emailData),
        }
      );
      const data = await response.json();
      sessionStorage.setItem("secret_key", data.secret);

      setFormStep(2);
      toast.success("OTP sent to your Email ID.");
    } catch (err) {
      toast.error("Something wrong on our side! Please try again later.");
      console.log("Error while generateotp", err);
    }
  };

  if (!exists) {
    return (
      <div className="flex flex-col h-[700px] justify-center items-center gap-8 mx-4 md:mx-0">
        <div className="text-center">
          <h2 className="text-gray font-productSans font-bold text-[28px] md:text-[52px] tracking-wide overflow-hidden whitespace-nowrap animate-typing">
            Welcome to <span className="text-black">TheraScribeAI</span>
          </h2>
        </div>
        {formVisible && (
          <>
            <h4 className="text-black font-dmSans font-medium md:text-[20px] text-center animate-fadeIn">
              We will mail you a temperory code to access the demo
            </h4>

            <form
              onSubmit={submitFunction}
              className="flex flex-row justify-between md:w-[450px] gap-4 animate-fadeIn"
            >
              {formStep === 1 && (
                <input
                  type="text"
                  title="Email ID"
                  value={email}
                  placeholder="Enter your Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="flex-1 border-[0.5px] text-black focus:outline-none focus:border-[0.5px] bg-white border-gray focus:border-black p-2 font-normal rounded-md"
                />
              )}

              {formStep === 2 && (
                <input
                  type="text"
                  title="OTP"
                  value={OTP}
                  placeholder="Enter your 6 digit OTP"
                  onChange={(e) => setOTP(e.target.value)}
                  required
                  className="flex-1 border-[0.5px] text-black focus:outline-none focus:border-[0.5px] bg-white border-gray focus:border-black p-2 font-normal rounded-md"
                />
              )}
              <button className="bg-gray text-white font-medium py-2 px-8 rounded-md">
                {formStep === 1 ? "Submit" : loading ? "Verifying..." : "Verify OTP"}
              </button>
            </form>

            {formStep === 2 && (
              <div className="text-lightGray text-[14px] animate-fadeIn">
                Did not receive OTP?{" "}
                <button
                  onClick={NotReceivedOTP}
                  className="text-gray cursor-pointer"
                >
                  Resend OTP
                </button>
              </div>
            )}
          </>
        )}
      </div>
    );
  }

  // User already registered
  else {
    return (
      <div className="flex flex-col justify-center items-center gap-2 mx-4 md:mx-0">
        <div className="flex flex-col items-center text-center mt-16 mb-2">
          <h2 className="text-gray font-productSans font-bold text-[28px] md:text-[42px] tracking-wide animate-fadeIn">
            Welcome to <span className="text-black">TheraScribeAI</span>
          </h2>

          <button className="flex items-center font-demoSans bg-gray hover:shadow-md text-white font-normal text-[16px] px-6 py-2 mt-4 rounded-md animate-fadeIn">
            Join Waitlist <FaArrowRight className="ml-2" />
          </button>
        </div>

        <div className="flex flex-col lg:flex-row gap-4 p-4 lg:p-10">
          {/* Video */}
          <div className="lg:w-3/5">
            <video autoPlay loop muted controls>
              <source src="./assets/video/demo.mp4" type="video/mp4" />
            </video>
          </div>

          {/* Rolling Text */}
          <TypingText />
        </div>
      </div>
    );
  }
};

export default Demo;
