import { useEffect, useState, useRef } from "react";

const TypingText = () => {
  const text = `Thhis is a demonstration of the TheraScribeAI clinical application, that generates clinical notes autonomously using a web camera, and microphone connected to an existing EMR terminal or laptop used by physical therapists for clinical notes.
  The application will integrate into existing EMR platforms and intake information is available to PT for review as well as previous summary notes, or history for review upon login. The PT clicks on the type of evaluation being performed and the system takes kicks of a HIPAA, AI and Data Consent that the PT and patient must agree on before the session can begin in the application. This allows for informed consent from both the PT and patient for research and development of the product.
  The applications designed to be visible to both the PT and patient during the session. The application shows the live video and audio capture during the clinical session while also obfuscating P.I.I. Various sections of the SOAP Notes e that the PT typically used to filling out during a session are displayed along with predictive queues. During the subjective portion of the evaluation, TheraScribeAI accurately transcribes in real time, followed by proprietary AI models being used to capture clinical context in the summary. The PT is given contextual cues as an “scribe-aid”, which helps create a more complete summary.
  Data science and predictive analytics are used to provide context cues and summaries and as the transcription establishes evidence of capturing a specific cue, the note
  evolves. The more context and complete the evaluation, the fewer cues are displayed on the application. The objective assessment uses computer vision and AI to capture range of motion and other kinematic information that are important for this specific type of evaluation. Historical evaluations, demographic information and clinical context are used to train the Objective needs for a specific type of Physical therapy evaluation.
  In the assessment and plan sections of the application, TheraScribeAI build suggestive care plans, goals, suggested treatment frequency etc. Generative AI and data science input specific compliance information and potential home exercise programming that would suit the type of clinical necessity for the patient.
  As the session nears completion, the PT can generate a note on the application. This results in a clinical note generated using AI and clinical context that allows for direct interaction with the physical therapy. The PT is provided with key metrics, and interactive capabilities to put final touches to the AI generated notes. This specific personalization also further feeds into the training of our Algorithm to further provide accuracy and predictability.
  The final note signed off by the PT creates a clinical note that is complaint, efficient and specific to the patient and the clinical practices used by the physical therapist.`;

  const [displayedText, setDisplayedText] = useState("");
  const typingSpeed = 20; // Speed of typing in milliseconds
  const scrollRef = useRef(null); // Ref for the scrollable container

  useEffect(() => {
    let index = 0; // Index to keep track of the current character

    const interval = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text[index]);
        index++;

        // Scroll to the bottom of the container
        if (scrollRef.current) {
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
      } else {
        clearInterval(interval);
      }
    }, typingSpeed);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);
  return (
    <div
      ref={scrollRef}
      className="lg:w-2/5 lg:h-[450px] px-8 overflow-y-scroll relative"
    >
      <div className="space-y-2">
        <p className="text-gray font-dmSans font-normal md:text-[16px] leading-relaxed">{displayedText}</p>
      </div>
    </div>
  );
};

export default TypingText;
